import { TextField, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useRef, useState } from "react";
import "./scss/UserSearch.scss";

const SearchBar = ({ setSearchQuery, show = false }) => {
  const [showSearch, setShowSearch] = useState(show);
  const searchFieldRef = useRef(null);
  const searchClearRef = useRef(null);

  const handleSearchClick = () => {
    setShowSearch(!showSearch);
  };

  const handleSearchClear = () => {
    searchFieldRef.current.blur();
    searchClearRef.current.blur();
    setShowSearch(false);
    setSearchQuery("");
  };

  return (
    <div
      className={`search-container ${
        showSearch || showSearch ? "search-container--active" : ""
      }`}
      data-testid="search-container"
    >
      <IconButton
        className="searchbar__search-icon"
        data-testid="search-icon"
        aria-label="search"
        variant="large"
        onClick={handleSearchClick}
      >
        <SearchIcon />
      </IconButton>
      {showSearch ? (
        <>
          <TextField
            ref={searchFieldRef}
            className="searchbar__search-input"
            inputProps={{ "data-testid": "search-input" }}
            size="small"
            onInput={(e) => {
              setSearchQuery(e.target.value);
            }}
            variant="outlined"
            placeholder="Search Users by ID"
          />
          <IconButton
            data-testid="clear-search-button"
            ref={searchClearRef}
            aria-label="close-search"
            sx={{ fontSize: "xx-large" }}
            onClick={handleSearchClear}
          >
            <ClearIcon />
          </IconButton>
        </>
      ) : null}
    </div>
  );
};

export default SearchBar;
