import { TabContext, TabPanel } from "@mui/lab";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { UserContext } from "@torc-robotics/mcli-mfui-auth";
import CreateMissionDetails from "./CreateMissionDetails";
import "react-toastify/dist/ReactToastify.css";
import UserList from "./users/UserList";
import RoleList from "./RoleList";

const AdminPage = () => {
  const { hasRole } = useContext(UserContext);
  const [tab, setTab] = useState("userList");

  if (!hasRole(["SuperAdmin"])) {
    return (
      <Box sx={{ width: "100%", height: "100%" }}>
        <Typography variant="h3">
          You do not have access to this page
        </Typography>
      </Box>
    );
  }

  const adminTabs = [
    {
      key: "userList",
      title: "Users",
      component: <UserList />,
    },
    {
      key: "roleList",
      title: "Roles",
      component: <RoleList />,
    },
    {
      key: "createMission",
      title: "Create Mission",
      component: <CreateMissionDetails />,
    },
  ];

  return (
    <div id="main-content">
      <TabContext value={tab}>
        <Box sx={{ width: "100%", height: "100%" }}>
          <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)}>
            {adminTabs.map((tab) => (
              <Tab
                label={tab.title}
                value={tab.key}
                key={tab.key}
                data-testid={tab.title}
                data-dd-action-name={tab.title}
              />
            ))}
          </Tabs>
          {adminTabs.map((tab) => (
            <TabPanel value={tab.key} key={`content-${tab.key}`}>
              <div>{tab.component}</div>
            </TabPanel>
          ))}
        </Box>
      </TabContext>
    </div>
  );
};

export default AdminPage;
