import { TableCell, TableRow, Typography, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { getRolesString } from "../../../framework/utils/Utils";
import DeleteUserDialog from "./DeleteUserDialog";
import { useState } from "react";
import EditUserDialog from "./EditUserDialog";

const UserRow = ({ user, allRoles }) => {
  const [showDeleteDialog, setShowDelete] = useState(false);
  const [showEditUser, setShowEdit] = useState(false);

  const showDeleteUser = () => {
    setShowDelete(true);
  };

  const onCancelDelete = () => {
    setShowDelete(false);
  };

  const onCancelEdit = () => {
    setShowEdit(false);
  };

  return (
    <>
      <TableRow
        key={user.email}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell scope="row">
          <Typography variant="overline" gutterBottom sx={{ display: "block" }}>
            {user.email}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography variant="caption" gutterBottom sx={{ display: "block" }}>
            {getRolesString(allRoles, user.roles)}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="edit"
            data-testid={`${user.email}-edit-button`}
            color="primary"
            onClick={() => {
              setShowEdit(true);
            }}
          >
            <EditIcon />
          </IconButton>
        </TableCell>
        <TableCell align="left">
          <IconButton
            data-testid={`${user.email}-delete-button`}
            aria-label="delete"
            color="error"
            onClick={showDeleteUser}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {showDeleteDialog ? (
        <DeleteUserDialog show={true} onClose={onCancelDelete} user={user} />
      ) : null}
      {showEditUser ? (
        <EditUserDialog
          key={user.email}
          show={true}
          onClose={onCancelEdit}
          user={user}
          allRoles={allRoles}
        />
      ) : null}
    </>
  );
};
export default UserRow;
