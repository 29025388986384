import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useMemo, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  FormControl,
  FormLabel,
  Select,
  Typography,
  FormHelperText,
  TextField,
  MenuItem,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
  Alert,
  AlertTitle,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";

import { UserContext } from "@torc-robotics/mcli-mfui-auth";
import { APIPost } from "../../framework/api/Api";
import { caseConverter } from "../../framework/utils/Utils";
import { getRouteSummaries } from "../../framework/api/RouteSummaryApi";
import VehiclesApi from "../../framework/api/VehiclesAPI";
import useGetV2VehiclesQuery from "../../hooks/query/vehicle/useGetV2VehiclesQuery";

// yup validator
const schema = yup.object().shape({
  vehicle_id: yup.string().required(),
  load_id: yup.string().required(),
  safety_conductor_email: yup
    .string()
    .email("Valid Email Required")
    .required("Email Required"),
  safety_driver_email: yup
    .string()
    .email("Valid Email Required")
    .required("Email Required"),
  mission_manager_email: yup
    .string()
    .email("Valid Email Required")
    .required("Email Required"),
});

const UnsupportedFeatureWarning = () => {
  return (
    <Alert variant="filled" severity="error">
      <AlertTitle>Unsupported Feature Notice</AlertTitle>
      Creating missions through this admin page is unsupported. Missions created through this feature will likely experience issues. If you need to create a mission please do so through the TREQS/TMS systems.
    </Alert>
  );
};

/* istanbul ignore next */
/* deprioritized */
const MAX_PASSENGERS = 2;
/* istanbul ignore next */
/* deprioritized */
const CreateMissionDetails = () => {
  const { hasAnyPermissions } = useContext(UserContext);
  const [vehicle, setVehicle] = useState({});
  const [routes3] = useState([
    {
      key: "24060",
      destination_display: "Blacksburg, VA",
      start_display: "Blacksburg, VA",
      start_zone: "24060",
      destination_zone: "24060",
      mission_route: "",
    },
    {
      key: "24061",
      destination_display: "Blacksburg, VA",
      start_display: "Blacksburg, VA",
      mission_route: "",
      start_zone: "24061",
      destination_zone: "24061",
    },
    {
      key: "78801",
      destination_display: "Uvalde, TX",
      start_display: "Uvalde, TX",
      mission_route: "",
      start_zone: "78801",
      destination_zone: "78801",
    },
  ]);

  const { handleSubmit, reset, control, formState } = useForm({
    defaultValues: {
      vehicle_id: "",
      id: "",
      load_id: "",
      safety_conductor_email: "",
      safety_driver_email: "",
      mission_manager_email: "",
      description: "",
      mission_route: "",
      start_display: "",
      start_zone: "",
      destination_display: "",
      destination_zone: "",
      start_time: new Date(),
      passengers: [],
      tms_trip_id: "0",
      tms_order_id: "ADMIN-CREATED-MISSION",
      tms_freight_bill_number: "",
      tms_state: "PENDING",
      tms_id: "ADMIN-CREATED-MISSION",
      tms_schedule_arrival_time: new Date(
        new Date().setHours(new Date().getHours() + 3)
      ), // ??? is there an easier way to write this lol
      tms_schedule_departure_time: new Date(),
    },
    resolver: yupResolver(schema),
  });

  const {
    fields: passengersFields,
    append: passengersAppend,
    remove: passengersRemove,
  } = useFieldArray({
    control,
    name: "passengers",
  });

  const { data: vehicles, isLoading: vehiclesLoading } = useGetV2VehiclesQuery()

  const { data: routeSummaryData, isLoading: routesLoading } = useQuery({
    queryKey: ["routeSummaries"],
    queryFn: getRouteSummaries,
    enabled: hasAnyPermissions(["SuperAdmin", "LaunchMission"]),
  });

  const destinations = useMemo(() => {
    if (parseFloat(vehicle?.gen) >= 3.0) {
      return routes3.map((route) => (
        <MenuItem
          data-testid={route.key}
          key={route.key}
          value={route.destination_zone}
        >
          {`${route.destination_display} ${route.destination_zone}`}
        </MenuItem>
      ));
    } else {
      return routeSummaryData?.items.map((route) => (
        <MenuItem
          data-testid={route.destination_display}
          key={route.destination_display}
          value={route.destination_display}
        >
          {route.destination_display}
        </MenuItem>
      ));
    }
  }, [vehicle, routeSummaryData, routes3]);

  const starts = useMemo(() => {
    if (parseFloat(vehicle?.gen) >= 3.0) {
      return routes3.map((route) => (
        <MenuItem
          data-testid={route.key}
          key={route.key}
          value={route.start_zone}
        >
          {`${route.destination_display} ${route.destination_zone}`}
        </MenuItem>
      ));
    } else {
      const uniqueStartDisplays = new Set();

      routeSummaryData?.items.forEach((item) => {
        uniqueStartDisplays.add(item.start_display);
      });

      return Array.from(uniqueStartDisplays).map((display) => (
        <MenuItem data-testid={display} key={display} value={display}>
          {display}
        </MenuItem>
      ));
    }
  }, [vehicle, routeSummaryData, routes3]);

  const passengerToArray = (passengers) => {
    return passengers
      .filter((value) => value.passenger_info != "")
      .map((value) => caseConverter(value.passenger_info));
  };

  const handleCreateMission = (data) => {
    let destination_routeSummary = {};
    let start_routeSummary = {};

    //I hate that I need to support 2 data sets
    if (parseFloat(vehicle?.gen) < 3.0) {
      destination_routeSummary = _.find(routeSummaryData?.items, [
        "destination_display",
        data.destination_display,
      ]);
      start_routeSummary = _.find(routeSummaryData?.items, [
        "start_display",
        data.start_display,
      ]);
    } else {
      destination_routeSummary = _.find(routes3, [
        "destination_zone",
        data.destination_display,
      ]);
      start_routeSummary = _.find(routes3, ["start_zone", data.start_display]);
    }

    const missionBody = {
      headers: {},
      body: {
        vehicle_id: vehicle.vehicle_id,
        load_id: data.load_id.toUpperCase(),
        safety_conductor_email: caseConverter(data.safety_conductor_email),
        safety_driver_email: caseConverter(data.safety_driver_email),
        mission_manager_email: caseConverter(data.mission_manager_email),
        description: _.toLower(data.description),
        mission_route: destination_routeSummary.mission_route,
        destination_display: destination_routeSummary.destination_display,
        destination_zone: destination_routeSummary.destination_zone,
        start_zone: start_routeSummary.start_zone,
        start_display: start_routeSummary.start_display,
        start_time: data.start_time.toISOString(),
        passengers: passengerToArray(data.passengers),
        tms_trip_id: data.tms_trip_id,
        tms_order_id: data.tms_order_id,
        tms_freight_bill_number: data.tms_freight_bill_number,
        tms_state: data.tms_state,
        tms_id: data.tms_id,
        tms_schedule_arrival_time: data.tms_schedule_arrival_time.toISOString(),
        tms_schedule_departure_time:
          data.tms_schedule_departure_time.toISOString(),
      },
    };

    APIPost("MissionsApi", "/missions", missionBody).then((mission) => {
      if (mission && mission.error) {
        if (mission.error.request && mission.error.request.response) {
          toast.error(
            _.get(
              mission,
              "error.response.data",
              `Server Error - ${_.get(
                mission,
                "error.response.status",
                500
              )} - Please contact support if the error continues.`
            )
          );
          console.error(
            "Error Creating Mission: " + mission.error.request.response
          );
        }
        toast.error(
          "An error occured when creating a mission. Please contact support if the error continues. "
        );
        console.error("Error Creating Mission: " + mission.error);
      } else {
        toast.success("Created Mission " + mission.load_id);
        reset();
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(handleCreateMission)} onReset={reset}>
      <div
        style={{
          display: "grid",
          gap: "1rem",
          backgroundColor: "white",
          padding: "2rem",
          borderRadius: "1rem",
          maxWidth: "1024px",
        }}
      >
        <UnsupportedFeatureWarning />
        <Typography variant="h4">Create Mission</Typography>

        <Typography variant="h5">Mission Details</Typography>
        <Box
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          sx={{
            gap: 2,
            "@media (max-width: 600px)": {
              gridTemplateColumns: "repeat(1, 1fr)",
            },
          }}
        >
          <FormControl>
            <FormLabel id="vehicle_id">
              Vehicle ID {vehiclesLoading && <CircularProgress size={12} />}
            </FormLabel>
            <Controller
              name="vehicle_id"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <Select
                    onClick={(event) => {
                      setVehicle(vehicles[event.target.dataset.value]);
                    }}
                    data-testid="vehicle_id"
                    {...field}
                    size="small"
                  >
                    {vehicles?.map((truck, index) => (
                      <MenuItem
                        data-testid={truck.vehicle_id}
                        key={truck.vehicle_id}
                        value={index}
                      >
                        {truck.vehicle_id} - {truck.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {fieldState.error?.message}
                  </FormHelperText>
                </>
              )}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Mission ID (Load ID)</FormLabel>
            <Controller
              name="load_id"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <TextField data-testid="load_id" {...field} size="small" />
                  <FormHelperText error>
                    {fieldState.error?.message}
                  </FormHelperText>
                </>
              )}
            />
          </FormControl>
        </Box>
        <Typography variant="h5">Route</Typography>
        <Box
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          sx={{
            gap: 2,
            "@media (max-width: 600px)": {
              gridTemplateColumns: "repeat(1, 1fr)",
            },
          }}
        >
          <FormControl>
            <FormLabel id="start_display">Departure Hub</FormLabel>
            <Controller
              name="start_display"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <Select data-testid="start_display" {...field} size="small">
                    {starts}
                  </Select>
                  <FormHelperText error>
                    {fieldState.error?.message}
                  </FormHelperText>
                </>
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel id="destination_display">
              Destination Hub {routesLoading && <CircularProgress size={10} />}
            </FormLabel>
            <Controller
              name="destination_display"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <Select
                    data-testid="destination_display"
                    {...field}
                    size="small"
                  >
                    {destinations}
                  </Select>
                  <FormHelperText error>
                    {fieldState.error?.message}
                  </FormHelperText>
                </>
              )}
            />
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl>
              <FormLabel id="start_time">Date of Departure</FormLabel>
              <Controller
                name="tms_schedule_departure_time"
                control={control}
                render={({ field }) => (
                  <DateTimePicker
                    {...field}
                    renderInput={(props) => (
                      <TextField {...props} size="small" />
                    )}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <FormLabel id="end_time">Date of Arrival</FormLabel>
              <Controller
                name="tms_schedule_arrival_time"
                control={control}
                render={({ field }) => (
                  <DateTimePicker
                    {...field}
                    renderInput={(props) => (
                      <TextField {...props} size="small" />
                    )}
                  />
                )}
              />
            </FormControl>
          </LocalizationProvider>
        </Box>

        <Typography variant="h5">TMS</Typography>
        <Box
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          sx={{
            gap: 2,
            "@media (max-width: 600px)": {
              gridTemplateColumns: "repeat(1, 1fr)",
            },
          }}
        >
          <FormControl>
            <FormLabel id="tms_state">TMS State</FormLabel>
            <Controller
              name="tms_state"
              control={control}
              render={({ field }) => (
                <Select {...field} size="small">
                  <MenuItem value="PENDING">PENDING</MenuItem>
                  <MenuItem value="IN_PROGRESS">IN_PROGRESS</MenuItem>
                  <MenuItem value="COMPLETED">COMPLETED</MenuItem>
                </Select>
              )}
            />
          </FormControl>

          <FormControl>
            <FormLabel id="tms_id">TMS ID</FormLabel>
            <Controller
              name="tms_id"
              control={control}
              render={({ field }) => (
                <TextField {...field} disabled size="small" />
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel id="tms_trip_id">Trip ID</FormLabel>
            <Controller
              name="tms_trip_id"
              control={control}
              render={({ field }) => (
                <TextField {...field} disabled size="small" />
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel id="tms_order_id">Order ID</FormLabel>
            <Controller
              name="tms_order_id"
              control={control}
              render={({ field }) => (
                <TextField {...field} disabled size="small" />
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel id="tms_freight_bill_number">
              Freight Bill Number
            </FormLabel>
            <Controller
              name="tms_freight_bill_number"
              control={control}
              render={({ field }) => <TextField {...field} size="small" />}
            />
          </FormControl>
        </Box>

        <Typography variant="h5">Crew</Typography>
        <Box display="grid" gridTemplateColumns="repeat(1, 1fr)">
          <FormControl>
            <FormLabel id="safety_conductor_email">Safety Conductor</FormLabel>
            <Controller
              name="safety_conductor_email"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <TextField
                    data-testid="safety_conductor_email"
                    {...field}
                    placeholder="name@torc.ai"
                    size="small"
                  />
                  <FormHelperText error>
                    {fieldState.error?.message}
                  </FormHelperText>
                </>
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel id="safety_driver_email">IFTD</FormLabel>
            <Controller
              name="safety_driver_email"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <TextField
                    data-testid="safety_driver_email"
                    {...field}
                    placeholder="name@torc.ai"
                    size="small"
                  />
                  <FormHelperText error>
                    {fieldState.error?.message}
                  </FormHelperText>
                </>
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel id="mission_manager_email">Mission Manager</FormLabel>
            <Controller
              name="mission_manager_email"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <TextField
                    data-testid="mission_manager_email"
                    {...field}
                    placeholder="name@torc.ai"
                    size="small"
                  />
                  <FormHelperText error>
                    {fieldState.error?.message}
                  </FormHelperText>
                </>
              )}
            />
          </FormControl>
          {passengersFields.map((item, index) => (
            <FormControl key={item.id}>
              <FormLabel>Passenger {index + 1}</FormLabel>
              <Controller
                name={`passengers[${index}].passenger_info`}
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <TextField
                      data-testid="passenger_info"
                      {...field}
                      placeholder="Passenger Info"
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="passenger info clear"
                              onClick={() => passengersRemove(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText error>
                      {fieldState.error?.message}
                    </FormHelperText>
                  </>
                )}
              />
            </FormControl>
          ))}
          <Button
            onClick={() => passengersAppend({ passenger_info: "" })}
            size="small"
            hidden={passengersFields.length >= MAX_PASSENGERS}
          >
            Add Passenger
          </Button>
        </Box>

        <Typography variant="h5">Mission Description</Typography>
        <Box display="grid" gridTemplateColumns="repeat(1, 1fr)">
          <FormControl>
            <FormLabel id="description">Description</FormLabel>
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <TextField
                    data-testid="description"
                    {...field}
                    placeholder="Description"
                    multiline
                    rows={3}
                  />
                  <FormHelperText error>
                    {fieldState.error?.message}
                  </FormHelperText>
                </>
              )}
            />
          </FormControl>
        </Box>

        <UnsupportedFeatureWarning />

        <Box
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          sx={{ gap: 2 }}
        >
          <Button type="reset" variant="outlined">
            Cancel
          </Button>
          <Button
            type="submit"
            variant="outlined"
            color="success"
            // disabled={formState.isSubmitting}
            endIcon={formState.isSubmitting && <CircularProgress size={18} />}
          >
            Create Mission
          </Button>
        </Box>
      </div>
    </form>
  );
};

export default CreateMissionDetails;
