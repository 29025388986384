import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControlLabel, Checkbox } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import UsersAPI from "../../../framework/api/UsersAPI";
import { toast } from "react-toastify";
import { useState } from "react";
import { remove, includes } from "lodash";

function EditUserDialog({ show, onClose, user, allRoles }) {
  const [selectedRoles, setSelectedRoles] = useState(user?.roles);
  const queryClient = useQueryClient();

  const updateUser = useMutation({
    mutationKey: ["users", user.email, "update"],
    mutationFn: () =>
      UsersAPI.putUserV1UsersUserEmailPut({
        userEmail: user.email,
        requestBody: {
          email: user.email,
          roles: selectedRoles,
        },
      }),
    onSuccess: () => {
      toast.success(`Successfully updated ${user.email}.`, {
        toastId: "success-update",
      });
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
    onError: () => {
      /* istanbul ignore next */
      toast.error("An error occurred when attempting to create the user.");
    },
  });

  const setRoles = (role) => {
    let newRoles = selectedRoles ? selectedRoles : [];
    if (role?.currentTarget.checked) {
      newRoles.push(role.currentTarget.id);
    } else {
      remove(newRoles, function (object) {
        return object === role.currentTarget.id;
      });
    }
    setSelectedRoles(newRoles);
  };

  const mapRoles = () => {
    return allRoles?.map((role) => {
      return (
        <FormControlLabel
          key={role.uuid}
          className="row__role"
          control={
            <Checkbox
              data-testid={`${role.name}-check`}
              id={role.uuid}
              defaultChecked={!!includes(selectedRoles, role.uuid)}
              size="small"
              onChange={(event) => setRoles(event)}
            />
          }
          label={role.name}
          slotProps={{ typography: { variant: "overline" } }}
        />
      );
    });
  };

  return (
    <Dialog
      className="update__dialog"
      open={show}
      onClose={onClose}
      data-testid="update-dialog"
    >
      <DialogTitle id="alert-dialog-title">{`Editing ${user.email}`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {mapRoles()}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="close-edit-user"
          variant="contained"
          color="error"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          data-testid="submit-edit-user"
          variant="contained"
          color="success"
          onClick={async () => {
            await updateUser.mutateAsync();
            onClose();
          }}
          autoFocus
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditUserDialog;
